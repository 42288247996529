<template>
<h1>服务器错误</h1>
</template>

<script>
export default {
  name: 'Error'
}
</script>

<style scoped>

</style>
